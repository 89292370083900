'use client';

import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { percentageWatchedViewingHistoryAtom } from '@/lib/atoms/viewing-history';
import { ViewingHistoryItem } from '@/lib/types/api/viewing-history';
import styles from './UserProgressBar.module.scss';
interface UserProgressBarProps {
  slug: string;
  depPercentageWatched?: number;
  className?: string;
}
const UserProgressBar = (props: UserProgressBarProps) => {
  const {
    slug,
    depPercentageWatched,
    className
  } = props;
  const [userViewingHistory] = useAtom(percentageWatchedViewingHistoryAtom);
  const [percentageWatched, setPercentageWatched] = useState<number | null>(null);
  let classNames = styles.progress;
  if (className) {
    classNames += ` ${className}`;
  }
  useEffect(() => {
    const found = userViewingHistory?.find((viewingHistoryItem: ViewingHistoryItem) => viewingHistoryItem.slug === slug);
    if (depPercentageWatched) {
      setPercentageWatched(depPercentageWatched);
    } else {
      setPercentageWatched(found ? Math.round(found.percentageWatched) : null);
    }
  }, [slug, depPercentageWatched, userViewingHistory]);
  if (percentageWatched) {
    return <>
        <progress className={classNames} value={percentageWatched} max="100" aria-label='Percentage Watched'>
            {percentageWatched}%
        </progress>
      </>;
  }
};
export default UserProgressBar;