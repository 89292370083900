import { atomWithStorage } from 'jotai/utils';
import { ViewingHistoryItem } from '@/lib/types/api/viewing-history';

let storedHistory

if (typeof window !== "undefined") {
  storedHistory = JSON.parse(window.localStorage.getItem('viewing_history')!)
} else {
  storedHistory = null
}

export const percentageWatchedViewingHistoryAtom = atomWithStorage<ViewingHistoryItem[] | null>('percentageWatchedViewingHistory', storedHistory);
