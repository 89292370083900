'use client';

// imports
import { useAtom } from 'jotai';

// lib files
import { userProfile } from '@/lib/atoms/profile';
import { stationDataAtom } from '@/lib/atoms/station-data';

// components
import LinkButton from '@/components/Button/LinkButton';

// styles
import styles from './PassportHoverOverlay.module.scss';
interface PassportHoverOverlayProps {
  className: string;
}
const PassportHoverOverlay = (props: PassportHoverOverlayProps) => {
  const {
    className
  } = props;
  const [profile] = useAtom(userProfile);
  const [stationData] = useAtom(stationDataAtom);
  if (!stationData || stationData && profile?.personal_data?.is_passport) {
    return null;
  }
  const {
    attributes: {
      passport_url,
      short_common_name
    }
  } = stationData!;
  const donateUrl = passport_url ? passport_url : 'https://www.pbs.org/donate';
  return <div className={`${styles.passport_hover_overlay} ${className}`} data-sentry-component="PassportHoverOverlay" data-sentry-source-file="PassportHoverOverlay.tsx">
      <p className={styles.passport_hover_overlay_text}>Watch this video with<br />{short_common_name} Passport.</p>

      <LinkButton href={donateUrl} className={styles.passport_button} size='min' data-sentry-element="LinkButton" data-sentry-source-file="PassportHoverOverlay.tsx">
        Donate & Start Watching
      </LinkButton>
    </div>;
};
export default PassportHoverOverlay;