'use client';

// imports
import { useEffect } from 'react';

// lib files
import { StationData } from "@/lib/types/api/stations-data";
export const gtmPageView = (props: GTMDataLayer) => {
  return window.dataLayer?.push({
    event: "page_view",
    url: window.location.href,
    ...props
  });
};
interface GTMPageViewProps {
  analyticsTitle?: string;
  isSVP: boolean;
  pageTrackingId?: string;
  showTitle?: string;
  videoTitle?: string;
  videoType?: string;
  videoTPMediaId?: string;
  stationData?: StationData;
}
interface GTMDataLayer {
  CurrentPage: string;
  PageTrackingId?: string;
  ShowTitle?: string;
  VideoTitle?: string;
  VideoTPMediaId?: string;
  VideoType?: string;
  StationTrackingId?: string;
  Station?: string;
}
const GTMPageView = (props: GTMPageViewProps) => {
  const {
    analyticsTitle,
    pageTrackingId,
    showTitle,
    videoTitle,
    videoType,
    videoTPMediaId,
    isSVP,
    stationData
  } = props;
  let stationPageTracking = null;
  let callSign = null;
  if (stationData) {
    stationPageTracking = stationData.attributes.page_tracking;
    callSign = stationData.attributes.call_sign;
  }
  useEffect(() => {
    const GTMDataLayer = window.GTMDataLayer || [];
    const dataLayerPayload: GTMDataLayer = {
      CurrentPage: analyticsTitle ? analyticsTitle : `Unspecified Page: ${window.location}`
    };
    if (pageTrackingId) {
      dataLayerPayload.PageTrackingId = pageTrackingId;
    }

    // ShowTitle is used for:
    // - Show Pages
    // - Franchise Pages
    // - Video Playback pages - the show the video belongs to
    if (showTitle) {
      dataLayerPayload.ShowTitle = showTitle;
    }
    if (videoTitle) {
      dataLayerPayload.VideoTitle = videoTitle;
    }
    if (videoTPMediaId) {
      dataLayerPayload.VideoTPMediaId = videoTPMediaId;
    }
    if (videoType) {
      // Report the VideoType as a capitalized string - e.g. "Preview"
      dataLayerPayload.VideoType = videoType.charAt(0).toUpperCase() + videoType.slice(1);
    }

    // this logic is a bit confusing:
    // 1. If we're on an SVP
    // 2. And we have a station page tracking ID
    // 3. And the station tracking ID is not the same as the page tracking ID
    //    - we do this last check because sometimes stations & producers are the same
    //    and this will prevent double registering of page views
    if (isSVP && stationPageTracking && stationPageTracking !== pageTrackingId) {
      dataLayerPayload.StationTrackingId = stationPageTracking;
    }
    if (callSign) {
      dataLayerPayload.Station = callSign;
    }

    // We don't *actually* send a page_view event, we only update the data layer.
    // There is a GTM Tag that actually registers the page view.
    // This matches production as of 10/2024.
    // Note - if you're working on this in dev, you will see this data layer push twice because it
    // happens inside a useEffect. This is normal and expected.
    GTMDataLayer?.push({
      ...dataLayerPayload
    });
  }, [analyticsTitle, callSign, isSVP, pageTrackingId, showTitle, stationData, stationPageTracking, videoTitle, videoTPMediaId, videoType]);
  return <></>;
};
export default GTMPageView;